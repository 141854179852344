<template>
   <component
      class="position-relative"
      :is="as"
      :class="{
         'amc-card': Card,
         'p-2': p2,
         'p-3': p3,
         'shadow-sm': shadow,
      }"
   >
      <span class="settings" v-if="Settings">
         <div class="dropdown">
            <svg
               class="dropdown-toggle"
               id="dropdownMenuButton1"
               data-bs-toggle="dropdown"
               aria-expanded="false"
               role="button"
               width="4"
               height="16"
               viewBox="0 0 4 16"
               fill="none"
               xmlns="http://www.w3.org/2000/svg"
            >
               <path
                  d="M2 16C0.89543 16 0 15.1046 0 14C0 12.8954 0.89543 12 2 12C3.10457 12 4 12.8954 4 14C4 15.1046 3.10457 16 2 16ZM2 10C0.89543 10 0 9.10457 0 8C0 6.89543 0.89543 6 2 6C3.10457 6 4 6.89543 4 8C4 8.53043 3.78929 9.03914 3.41421 9.41421C3.03914 9.78929 2.53043 10 2 10ZM2 4C0.89543 4 0 3.10457 0 2C0 0.89543 0.89543 0 2 0C3.10457 0 4 0.89543 4 2C4 2.53043 3.78929 3.03914 3.41421 3.41421C3.03914 3.78929 2.53043 4 2 4Z"
                  fill="#BCBCBC"
               />
            </svg>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
               <slot name="settings"></slot>
            </ul>
         </div>
      </span>
      <slot />
   </component>
</template>

<script>
export default {
   name: 'AmcCard',
   props: {
      as: {
         type: [String, Number],
         default: 'div',
      },
      Card: {
         type: Boolean,
         default: false,
      },
      p2: {
         type: Boolean,
         default: false,
      },
      p3: {
         type: Boolean,
         default: false,
      },
      shadow: {
         type: Boolean,
         default: false,
      },
      Settings: {
         type: Boolean,
         default: false,
      },
   },
};
</script>

<style scoped lang="scss">
.dropdown-menu {
   border-radius: 10px;
}
.settings {
   position: absolute;
   right: 15px;
   top: 15px;
}

.amc-card {
   border: 1px solid #e6e7e9;
   border-radius: 8px;
}
</style>
